import React from "react";
import { Grid, Theme, Typography, useMediaQuery } from "utils/material-ui-core";
import RHLink from "component-rh-link";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import memoize from "utils/memoize";
import RHImage from "component-rh-image";
import ProductOptionDropdown from "component-product-option-dropdown";
import { sortBy } from "lodash";
import he from "he";
import useTypographyStyles from "hooks/useTypographyStyles";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import RHPriceRangeDisplay from "component-rh-price-range-display";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";

import { usePageContent } from "customProviders/LocationProvider";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { countryNameMapper } from "resources/countries-config.json";
import { useRhUserAtomValue } from "hooks/atoms";
import yn from "yn";
import { useStyles } from "./ProductAddon.useStyles";
import useProductAddonData from "./useProductAddonData";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";
import { getPriceUserType } from "@RHCommerceDev/component-product-grid";

const ProductAddon = ({
  data,
  opts,
  productLineItemOptions,
  handleAddonData,
  loading,
  panelProdConfigurationData,
  imgURL
}) => {
  const env = useEnv();
  const { pageContent } = usePageContent();
  const redirectPath = useGetPDPRedirectPath(data);
  const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
  const classes = useStyles();
  const { userType } = useRhUserAtomValue();
  const country = getCountryFromUrl();
  const currencyCode = countryNameMapper[country];
  const lgUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"));

  const typographyStyles = useTypographyStyles({
    keys: [
      "rhBaseBody2",
      "rhBaseBody3",
      "rhBaseBody1",
      "rhBaseCaption",
      "rhBaseH3",
      "rhBaseH2",
      "uppercaseText"
    ]
  });

  const getQtyValue = env.FEATURE_PDP_DEFAULT_QUANTITY ? PDP_DEFAULT_QTY : 1;

  const {
    qty,
    setQty,
    selectedAddOnDisplayOption,
    setOptionsVisibility,
    onProductConfigChange,
    handleQuantitySelected,
    selectedOptions,
    saleMessageForPriceComponent
  } = useProductAddonData({
    data,
    opts,
    productLineItemOptions,
    handleAddonData
  });

  return (
    <>
      <Grid
        container
        xs={12}
        id={`component-product-addon-${data?.id}`}
        data-testid={`component-product-addon-${data?.id}`}
        key={`component-product-addon-${data?.id}`}
        className={"mb-2 mt-[68px]"}
      >
        {data?.productAddonTitle && (
          <Grid item xs={12}>
            <Typography
              className={classNames(
                typographyStyles?.rhBaseH2,
                typographyStyles?.uppercaseText,
                "font-[75]"
              )}
              dangerouslySetInnerHTML={{
                __html: `${he.decode(data?.productAddonTitle)} `
              }}
              align={"center"}
            />
          </Grid>
        )}
        {data?.productAddonMessage && (
          <Grid item xs={12} className={"pt-[5px]"}>
            <Typography
              className={classNames(
                typographyStyles?.rhBaseBody3,
                typographyStyles?.uppercaseText,
                "text-grey-900 font-extralight tracking-[0.04em]"
              )}
              dangerouslySetInnerHTML={{
                __html: `${he.decode(data?.productAddonMessage)} `
              }}
              align={"center"}
            />
          </Grid>
        )}

        {data?.productAddonDescription && (
          <Grid item xs={12} className={"pt-[23px]"}>
            <Typography
              className={classNames(
                typographyStyles?.rhBaseBody2,
                "text-gray-600 font-light"
              )}
              dangerouslySetInnerHTML={{
                __html: `${he.decode(data?.productAddonDescription)} `
              }}
              align={"center"}
            />
          </Grid>
        )}
        <Grid
          container
          className={"flex items-center justify-center pt-[30px]"}
        >
          <Grid
            item
            xs={12}
            className={classNames(
              [typographyStyles?.rhBaseBody3],
              "max-w-[291px] flex items-center justify-center"
            )}
          >
            <RHLink
              className="w-full max-w-[291px]"
              data-testid={`productImageLink-${data?.id}`}
              to={redirectPath}
              target="_blank"
            >
              <RHImage
                src={panelProdConfigurationData?.colorizedImgURL ?? imgURL}
                alt={data?.displayName}
                objectFit={"contain"}
                preset="mobileThumb"
                aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
              />
            </RHLink>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12} className={"pt-5"}>
            <Typography
              className={"text-center uppercase"}
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: `${he.decode(data?.displayName)} `
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={"flex items-center justify-center pt-1.5"}
          >
            {!opts?.length ? (
              <>
                <RHPriceRangeDisplay
                  data={data?.priceRangeDisplay}
                  topLabel={pageContent?.CONFIGURES_PRICE}
                  display={"inline"}
                  variant={"medium"}
                  memberPrice={
                    data?.priceRangeDisplay?.priceRange?.memberLowestListPrice
                  }
                  isMetaProduct={data?.metaProduct!}
                  nextGenDrivenOnSale={
                    data?.priceRangeDisplay?.nextGenDrivenOnSale!
                  }
                  skuOnSalePercentage={parseInt(
                    data?.saleInfo?.percentSaleSkus!
                  )}
                  currency={currencyCode}
                />
              </>
            ) : selectedOptions?.length === opts.length &&
              panelProdConfigurationData?.pricing ? (
              <>
                <RHRPriceDisplay
                  listPrice={panelProdConfigurationData?.pricing?.listPrice!}
                  memberPrice={
                    getPriceUserType(
                      userType!,
                      panelProdConfigurationData?.pricing
                    )!
                  }
                  topLabel={pageContent?.CONFIGURES_PRICE}
                  isSaleFilterEnabled={false}
                  onSale={
                    true
                      ? !!panelProdConfigurationData?.pricing?.salePrice
                      : panelProdConfigurationData?.pricing?.onSale!
                  }
                  skulowestMemberPrice={
                    getPriceUserType(
                      userType!,
                      panelProdConfigurationData?.pricing
                    )!
                  }
                  showSaleMessage={false}
                  userType={userType! || ""}
                  pageContent={pageContent}
                  variant={"medium"}
                  centerAlignFlag={true}
                  hideCss={true}
                  showMembershipProductPrice={
                    data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                    "true"
                  }
                  showGiftCardPrice={
                    data?.uxAttributes?.giftCert?.toLowerCase() === "true"
                  }
                />
              </>
            ) : (
              <>
                <RHRPriceDisplay
                  listPrice={data?.priceRangeDisplay?.listPrices?.[0]!}
                  memberPrice={data?.priceRangeDisplay?.memberPrices?.[0]!}
                  topLabel={
                    data.priceRangeDisplay?.overridePriceLabel ||
                    pageContent?.["STARTING_AT"]
                  }
                  isSaleFilterEnabled={false}
                  onSale={data?.priceRangeDisplay?.nextGenDrivenOnSale!}
                  skulowestMemberPrice={
                    data?.priceRangeDisplay?.skulowestMemberPrice!
                  }
                  showSaleMessage={
                    Number(data?.saleInfo?.percentSaleSkus) === 0 ||
                    Number(data?.saleInfo?.percentSaleSkus) === 100
                      ? false
                      : true
                  }
                  userType={userType! || ""}
                  pageContent={pageContent}
                  computedSalePageContent={saleMessageForPriceComponent}
                  variant={"medium"}
                  centerAlignFlag={true}
                  showMembershipProductPrice={
                    data?.uxAttributes?.membershipProduct?.toLowerCase() ===
                    "true"
                  }
                  showGiftCardPrice={
                    data?.uxAttributes?.giftCert?.toLowerCase() === "true"
                  }
                  hideCss={true}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          md={12}
          className={"pt-[29px] pb-2 flex items-center justify-center"}
        >
          {setOptionsVisibility(opts)?.filter(
            opt => opt?.isOptionSetVisible
          ) ? (
            <>
              <Grid item xs={5} md={4} spacing={lgUp ? 0 : 2}>
                <Grid item className={"pb-2.5 pl-2.5"}>
                  <ProductOptionDropdown
                    loading={loading}
                    id={`${data?.id}-qty-input`}
                    label="QTY"
                    options={Array.from({
                      length:
                        (yn(env?.FEATURE_USE_QUANTITY)
                          ? (panelProdConfigurationData?.maxQuantity ??
                              Number(getQtyValue)) > 40
                            ? 40
                            : Number(panelProdConfigurationData?.maxQuantity) <=
                              40
                            ? Number(panelProdConfigurationData?.maxQuantity)
                            : Number(getQtyValue)
                          : Number(getQtyValue)) + 1
                    }).map((item, index) => ({
                      key: `qty-${index}`,
                      value: `${index}`,
                      display: index
                    }))}
                    value={qty.toString()}
                    onChange={value => {
                      setQty(Number(value));
                      handleQuantitySelected(Number(value));
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={7} md={8}>
                {opts?.map((availableOption, index) => {
                  let options = availableOption.options?.filter(
                    option => option.status !== "unavailable"
                  );

                  if (availableOption.type === "Color") {
                    options = sortBy(options, o => o.value);
                  }

                  const selected =
                    options.find(option => option.status === "selected") ||
                    options.find(option =>
                      panelProdConfigurationData?.selectedOptions?.some(
                        configOpt => configOpt?.id === option?.id
                      )
                    ) ||
                    ({} as ProductAvailableOption);

                  // SR-2241 -Product Addon unsuppress matching line item dropdowns
                  // const optionsVisibility = setOptionsVisibility(opts);
                  // const hideOptionsSet = optionsVisibility?.some(
                  //   item =>
                  //     item.optionTypeId === availableOption.optionTypeId &&
                  //     item.isOptionSetVisible
                  // );
                  // if (hideOptionsSet) {
                  //   return null;
                  // }

                  return (
                    <>
                      <Grid
                        key={`availableOption-${index}`}
                        item
                        xs={12}
                        className={"pb-2.5 pl-2.5"}
                      >
                        <ProductOptionDropdown
                          loading={loading}
                          id={`optionSelect-${data?.id}-${availableOption.type}`}
                          label={availableOption.type}
                          disabled={
                            options.length < 1 &&
                            selected?.status === "selected"
                          }
                          options={options.map(option => ({
                            key: `${availableOption.type}-${option.id}`,
                            value: option.id,
                            display: he.decode(option.value)
                          }))}
                          value={selected?.id || ""}
                          onChange={value => {
                            onProductConfigChange(value, options, data?.id);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className={classNames(
                    typographyStyles?.rhBaseBody3,
                    "leading-[150%] uppercase pt-5 pb-2.5"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: `${
                      panelProdConfigurationData?.selectedOptions?.length ===
                        panelProdConfigurationData?.optionsLength &&
                      panelProdConfigurationData?.productSelected
                        ? panelProdConfigurationData?.preBillMessage
                        : ""
                    } `
                  }}
                  align={"center"}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={3} md={4} className={"pt-2.5"}>
              <Grid item xs={12}>
                <ProductOptionDropdown
                  loading={loading}
                  id={`${data?.id}-qty-input`}
                  label="QTY"
                  options={Array.from({
                    length:
                      (yn(env?.FEATURE_USE_QUANTITY)
                        ? (panelProdConfigurationData?.maxQuantity ??
                            Number(getQtyValue)) > 40
                          ? 40
                          : Number(panelProdConfigurationData?.maxQuantity) <=
                            40
                          ? Number(panelProdConfigurationData?.maxQuantity)
                          : Number(getQtyValue)
                        : Number(getQtyValue)) + 1
                  }).map((item, index) => ({
                    key: `qty-${index}`,
                    value: `${index}`,
                    display: index
                  }))}
                  value={qty.toString()}
                  onChange={value => {
                    setQty(Number(value));
                    handleQuantitySelected(Number(value));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default memoize(ProductAddon);
